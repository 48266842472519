import axios from "axios";
import React, { useEffect, useState } from "react";

const useAxios = (URL, PARAMS = {}, CONFIG = {}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  const request = async (url = URL, params = PARAMS, config = CONFIG) => {

    if (typeof url === "object") {
      config = JSON.stringify(params).length === JSON.stringify(PARAMS).length ? CONFIG : params;
      params = url;
      url = URL;
    }
    const { method = "get" } = config;
    setIsError(false);
    setError(null);
    setIsLoading(true);
    try {
      const { data } = await axios[method.toLowerCase()](url, params, config);
      console.log("data", data)
      setData(data);
      setIsLoading(false);
    } catch (error) {
      setError(error);
      setIsLoading(false);
    }
  };

  //   useEffect(() => {
  //     request();
  //   }, []);

  return { request, data, error, isLoading, isError };
};

export default useAxios;
