import React from "react";
import { useLocation } from "react-router-dom";

function useQuery() {
  const { search } = useLocation();

  const searchParams = React.useMemo(() => new URLSearchParams(search), [search]);
  const query = {};
  for (const [key, value] of searchParams.entries()) {
    query[key] = value;
  }

  return query;
}

export default useQuery;
