import * as React from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Avatar, Button, Card, CardActions, CircularProgress, Modal, TextField, Typography } from "@mui/material";

import PersonIcon from "@mui/icons-material/Person";

import Box from "@mui/material/Box";
import { useDispatch, useSelector } from "react-redux";

import { Register as userRegister, setUserError } from "../../rtk/features/auth/authSlice";
import jsonParse from "../../utils/jsonParse";
import isValidJSON from "../../utils/isValidJSON";
import { noticePopupStyle } from "../../consttant/style";

const Register = () => {
  const { user, subLoading, error } = useSelector((state) => state.auth);
  const [data, setData] = useState({});
  const [validationError, setValidationError] = useState([]);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (user?.emailVerified) {
      navigate("/");
    }
    if (user && !user?.emailVerified) {
      setOpen(true);
    }
  }, [user, navigate]);

  React.useEffect(() => {
    try {
      if (isValidJSON(error)) {
        setValidationError(jsonParse(error));
      }
    } catch (error) {}
  }, [error]);

  React.useEffect(() => {
    setOpen(false);
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!data.email || !data.password) {
      dispatch(setUserError("Field empty!"));
    } else {
      dispatch(userRegister(data));
    }
  };

  const handleFormInput = (e) => {
    if (e.target.name.includes(".")) {
      const steps = e.target.name.split(".");
      const tempVar = { ...data[steps[0]], [steps[1]]: e.target.value };
      setData({ ...data, [steps[0]]: tempVar });
    } else {
      setData({ ...data, [e.target.name]: e.target.value });
    }
  };

  React.useEffect(() => {
    dispatch(setUserError(null));
  }, [dispatch]);

  console.log({ open, user });

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
          alignItems: "center",
          justifyContent: "flex-start",
          // background: "url(https://source.unsplash.com/featured/1600x900)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        {!open && (
          <Card sx={{ minWidth: 300, marginTop: "6em", maxWidth: "472px" }}>
            <Box
              sx={{
                margin: "1em",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Avatar sx={{ bgcolor: "secondary.main", mt: 2 }}>
                <PersonIcon />
              </Avatar>
              {/* <Typography className="!text-2xl">bPlugins Dashboard</Typography> */}
            </Box>
            <Box
              sx={{
                marginTop: "1em",
                display: "flex",
                justifyContent: "center",
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <Typography className="!text-2xl "> Register</Typography>
            </Box>
            <Box sx={{ padding: "1em 1em 1em 1em" }}>
              <Box className="flex gap-5" sx={{ marginTop: "1em" }}>
                <TextField onChange={handleFormInput} margin="normal" required fullWidth id="email" label="First Name" name="name.firstName" autoComplete="firstName" autoFocus />
                <TextField onChange={handleFormInput} margin="normal" required fullWidth id="email" label="Last Name" name="name.lastName" autoComplete="lastName" autoFocus />
              </Box>
              <Box sx={{ marginTop: "1em" }}>
                <TextField className={`text-red-600`} onChange={handleFormInput} margin="normal" required fullWidth name="email" label="Email" type="email" id="email" autoComplete="email" />
              </Box>
              <Box sx={{ marginTop: "1em" }}>
                <TextField className={`text-red-600`} onChange={handleFormInput} margin="normal" required fullWidth name="password" label="Password" type="password" id="password" autoComplete="current-password" />
              </Box>
            </Box>
            <CardActions sx={{ padding: "0 1em 1em 1em" }}>
              <Button variant="contained" type="submit" color="primary" fullWidth disabled={subLoading}>
                {subLoading && <CircularProgress size={20} thickness={4} />}
                Register
              </Button>
            </CardActions>
            <Box className="text-center">
              <Typography>Already have an account? </Typography>
              <Typography className="text-sky-600">
                <Link to="/login">Login </Link>
              </Typography>
            </Box>
            <Box className="text-center flex justify-center p-3">
              {validationError?.length ? (
                validationError?.map((err, index) => (
                  <Typography key={index} className="text-red-600">
                    {err.message}
                  </Typography>
                ))
              ) : (
                <Typography className="text-red-600" dangerouslySetInnerHTML={{ __html: error }}></Typography>
              )}
            </Box>
          </Card>
        )}
        <Modal open={open} onClose={() => setOpen(false)} aria-labelledby="Success" aria-describedby="modal-modal-description">
          <Box sx={noticePopupStyle}>
            <Box className="text-center">
              <svg className="mx-auto" fill="#37D16B" xmlns="http://www.w3.org/2000/svg" height="60px" viewBox="0 0 512 512">
                <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z" />
              </svg>
            </Box>
            {user && !user.emailVerified && <Typography className="text-green-600 !text-xl w-4/5 text-center pb-3">Account verification email sent! Please check your inbox.</Typography>}
          </Box>
        </Modal>
      </Box>
    </form>
  );
};

export default Register;
