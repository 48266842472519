import { Box } from "@mui/material";
import React from "react";
import { Route, Routes, Switch, useLocation, useMatch } from "react-router-dom";
import Sidebar from "./components/Sidebar/Sidebar";
import AdminSidebar from "./components/Sidebar/AdminSidebar";
import PluginList from "./pages/plugin-list/PluginList";
import Users from "./pages/users/Users";

const Admin = () => {
  //   const { path } = useMatch();
  const location = useLocation();
  console.log(location.pathname);
  return (
    <Box className="flex flex-row">
      <Box style={{ with: "240px" }}>
        <AdminSidebar />
      </Box>
      {/* <Routes>
        <Route path="/plugin-list" element={<PluginList />} />
      </Routes> */}

      {/* Render the components conditionally based on the route */}
      {location.pathname === "/admin/users" && <Users />}
    </Box>
  );
};

export default Admin;
