import { Box, Drawer } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

import { fetchPlugins } from "../../rtk/features/plugin/pluginSlice";
import CustomerSidebar from "./CustomerSidebar";
import AdminSidebar from "./AdminSidebar";
import { setDrawerOpen } from "../../rtk/features/auth/authSlice";

const Sidebar = () => {
  const { activeRole, user, loading, drawerOpen } = useSelector((state) => state.auth);
  const { plugins } = useSelector((state) => state.plugin);
  const dispatch = useDispatch();

  const toggleDrawer = (open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    dispatch(setDrawerOpen(open));
  };

  console.log({ activeRole });

  useEffect(() => {
    if (!loading && user?._id && !plugins?.length) {
      dispatch(fetchPlugins(user?._id));
    }
  }, [user, loading, dispatch, plugins?.length]);

  return (
    <>
      {/* <Button onClick={toggleDrawer(true)}>{"Left"}</Button> */}
      {/* <Box className="bg-slate-300 overflow-y-scroll overflow-x-hidden" sx={{ width: "280px", height: "calc(100vh - 64px)" }}> */}
      <Box className="hidden md:block lg:block">{activeRole === "customer" ? <CustomerSidebar /> : <AdminSidebar />}</Box>
      {/* </Box> */}
      <Drawer anchor={"left"} open={drawerOpen} onClose={toggleDrawer(false)}>
        {/* <Box className="bg-slate-300 overflow-y-scroll overflow-x-hidden" sx={{ width: "280px", height: "calc(100vh)" }}> */}
        <Box>{activeRole === "customer" ? <CustomerSidebar /> : <AdminSidebar />}</Box>
        {/* </Box> */}
      </Drawer>
    </>
  );
};

export default Sidebar;
