import * as React from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Avatar, Button, Card, CardActions, CircularProgress, Modal, TextField, Typography } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import SendIcon from "@mui/icons-material/Send";

import Box from "@mui/material/Box";
import { useDispatch, useSelector } from "react-redux";
import { Login, sendPasswordResetLink, sendVerificationEmail, setUserError, setUserNotice } from "../../rtk/features/auth/authSlice";
import { noticePopupStyle } from "../../consttant/style";

const TestLogin = () => {
  const { user, notice, subLoading, error } = useSelector((state) => state.auth);
  const [credential, setCredential] = useState({});
  const [forgotPassword, setForgotPassword] = useState(false);
  const [emailNotVerified, setEmailNotVerified] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [modalOpen, setOpen] = useState(false);

  React.useEffect(() => {
    if (user?.emailVerified) {
      navigate("/");
    }
  }, [user, navigate]);

  React.useEffect(() => {
    // setOpen(false);
    if (!modalOpen) {
      dispatch(setUserNotice(null));
    }
  }, [dispatch, modalOpen]);

  React.useEffect(() => {
    if (!forgotPassword && error === "Email is not verified!") {
      setEmailNotVerified(true);
    }
  }, [error, forgotPassword]);

  React.useEffect(() => {
    if (!modalOpen) {
      setEmailNotVerified(false);
    }
  }, [modalOpen]);

  React.useEffect(() => {
    dispatch(setUserError(null));
  }, [forgotPassword, dispatch]);

  React.useEffect(() => {
    dispatch(setUserNotice(null));
    dispatch(setUserError(null));
  }, [dispatch]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!credential.email) {
      return dispatch(setUserError("Field empty!"));
    }

    if (forgotPassword) {
      return dispatch(sendPasswordResetLink(credential.email));
    }

    if (!credential.email || !credential.password) {
      dispatch(setUserError("Field empty!"));
    } else {
      dispatch(Login(credential));
    }
  };

  React.useEffect(() => {
    if (notice) {
      setOpen(true);
    }
  }, [notice]);

  const handleFormInput = (e) => {
    setCredential({ ...credential, [e.target.name]: e.target.value });
  };

  const handleSendVerificationEmail = async () => {
    dispatch(sendVerificationEmail(user._id));
  };

  console.log({ modalOpen, notice });

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
          alignItems: "center",
          justifyContent: "flex-start",
          // background: "url(https://source.unsplash.com/featured/1600x900)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        {(!modalOpen || !notice) && (
          <Card sx={{ minWidth: 320, maxWidth: "320px", marginTop: "6em" }}>
            {!emailNotVerified && (
              <>
                <Box
                  sx={{
                    margin: "1em",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Avatar sx={{ bgcolor: "secondary.main" }}>
                    <LockIcon />
                  </Avatar>
                </Box>
                <Box
                  sx={{
                    marginTop: "1em",
                    display: "flex",
                    justifyContent: "center",
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  {forgotPassword ? "Password Reset" : "Login"}
                </Box>
                <Box sx={{ padding: "0 1em 1em 1em" }}>
                  <Box sx={{ marginTop: "1em" }}>
                    <TextField onChange={handleFormInput} margin="normal" required fullWidth id="email" label="Email Address" name="email" autoComplete="email" autoFocus />
                  </Box>
                  {!forgotPassword && (
                    <Box sx={{ marginTop: "1em" }}>
                      <TextField className={`text-red-600`} onChange={handleFormInput} margin="normal" required fullWidth name="password" label="Password" type="password" id="password" autoComplete="current-password" />
                    </Box>
                  )}
                </Box>
                <CardActions sx={{ padding: "0 1em 1em 1em" }}>
                  <Button variant="contained" type="submit" color="primary" disabled={subLoading} fullWidth>
                    {subLoading && <CircularProgress size={20} thickness={5} />}
                    {forgotPassword ? "Send Password Reset Link" : "Login"}
                  </Button>
                </CardActions>
                {!forgotPassword && (
                  <Box className="text-center flex justify-center">
                    {/* {true && <SimpleLoader width="20px" />} */}
                    <Typography>Don't have an account yet? </Typography>
                    <Typography className="text-sky-600 !ml-1">
                      <Link to="/register"> Register </Link>
                    </Typography>
                  </Box>
                )}
                <Box className="text-center mt-3 text-blue-500">
                  <Typography onClick={() => setForgotPassword(!forgotPassword)}>
                    <Link to="#">{forgotPassword ? "Login" : "Forgot Password?"}</Link>
                  </Typography>
                </Box>

                <Box className="text-center p-3">
                  <Typography className="text-red-600" dangerouslySetInnerHTML={{ __html: error }}></Typography>
                </Box>
              </>
            )}
            {emailNotVerified && (
              <Box className="text-center p-3 pb-5">
                <Box className="text-center p-3">
                  <Typography className="text-red-600" dangerouslySetInnerHTML={{ __html: error }}></Typography>
                </Box>
                <Button endIcon={<SendIcon />} size="small" disabled={subLoading} onClick={handleSendVerificationEmail} variant="contained">
                  {subLoading && <CircularProgress size={20} thickness={5} />} Send Verification Email
                </Button>
              </Box>
            )}
          </Card>
        )}
        <Modal open={modalOpen && notice} onClose={() => setOpen(false)} aria-labelledby="Success" aria-describedby="modal-modal-description">
          <Box sx={noticePopupStyle}>
            <Box className="text-center">
              <svg className="mx-auto" fill="#37D16B" xmlns="http://www.w3.org/2000/svg" height="60px" viewBox="0 0 512 512">
                <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z" />
              </svg>
            </Box>
            {!forgotPassword && notice && (
              <Box className="text-center">
                <Typography className="text-green-500 !text-xl !mb-5">{notice}</Typography>
              </Box>
            )}

            {forgotPassword && notice && (
              <>
                <Typography className="text-center">We've sent password reset instruction to {credential?.email}.</Typography>
                <Typography className="text-center">If it doesn't arrive soon, check your spam folder or send the mail again.</Typography>
                <Typography className="text-center">
                  Need help?{" "}
                  <Link
                    component="button"
                    underline="always"
                    style={{
                      color: "blue",
                    }}
                    to="https://bplugins.com/support/"
                    target="_blank"
                  >
                    Contact support.
                  </Link>
                </Typography>

                <Typography style={{ color: "blue", cursor: "pointer" }} onClick={() => setOpen(false)}>
                  Back to sign-in
                </Typography>
              </>
            )}
          </Box>
        </Modal>
      </Box>
    </form>
  );
};

export default TestLogin;
