import axios from "../../../axios";
import { logout as pluginLogout } from "./../plugin/pluginSlice";
import { logout as pluginListLogout } from "./../pluginLIst/pluginListSlice";

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import validateError from "../../../utils/validateError";

// initial state
const initialState = {
  user: null,
  users: [],
  loggedIn: false,
  loading: true,
  error: null,
  activeRole: "customer",
  freemiusUser: null,
  drawerOpen: false,
  subLoading: false,
  verificationProcessing: true,
  notice: null,
  tokenVerified: false,
  currentUserId: null,
  selectedUser: {},
};

export const fetchUsers = createAsyncThunk("user/fetchUsers", async () => {
  try {
    const { data } = await axios.get("/api/v1/users");
    return data.data;
  } catch (error) {
    throw new Error(validateError(error));
  }
});

export const fetchFreemiusUser = createAsyncThunk("user/fetchFreemiusUser", async ({ pluginId, freemiusUserId, userId }) => {
  try {
    const response = await axios.get(`/api/v1/freemius/plugins/${pluginId}/users/${freemiusUserId}?user_id=${userId}&fields=email,public_key,secret_key,first,last`);
    return response.data.data;
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
});

export const updateProfile = createAsyncThunk("/user/updateProfile", async ({ userId, info }) => {
  try {
    const { data } = await axios.put(`/api/v1/users/${userId}`, info);
    return data?.data;
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
});

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
      state.loading = false;
    },
    setFreemiusUser: (state, action) => {
      state.freemiusUser = action.payload;
    },
    setUserRole: (state, action) => {
      state.activeRole = action.payload;
      localStorage.setItem("activeRole", action.payload);
    },
    userLogout: (state, action) => {
      state.user = null;
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
    },
    setUserError: (state, action) => {
      state.error = action.payload;
    },
    logout: (state) => {
      state = initialState;
      pluginLogout();
      pluginListLogout();
    },
    setDrawerOpen: (state, action) => {
      state.drawerOpen = action.payload;
    },
    setUserNotice: (state, action) => {
      state.notice = action.payload;
    },
    setSelectedUser: (state, action) => {
      state.selectedUser = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.pending, (state) => {
        // state.loading = true;
        state.error = null;
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.users = action.payload;
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });

    // fetch freemius user
    // builder.addCase(Login.pending, (state, action) => {
    //   // state.loading = true;
    //   state.error = null;
    // });
    builder.addCase(fetchFreemiusUser.fulfilled, (state, action) => {
      state.loading = false;
      state.freemiusUser = action.payload;
    });
    // builder.addCase(Login.rejected, (state, action) => {
    //   state.loading = false;
    //   state.error = "Invalid Email/Password!";
    // });

    builder
      .addCase(updateProfile.pending, (state, action) => {
        state.subLoading = true;
        state.error = null;
      })
      .addCase(updateProfile.fulfilled, (state, action) => {
        state.subLoading = false;
        // if (state.user._id === action.payload._id) {
        //   state.user = action.payload;
        // } else {
        state.users = state.users.map((item) => (item._id === action.payload._id ? action.payload : item));
        // }
      })
      .addCase(updateProfile.rejected, (state, action) => {
        state.subLoading = false;
        state.error = action.error.message;
      });
  },
});

export default userSlice.reducer;

export const { setUser, setUserRole, userLogout, setUserError, logout, setDrawerOpen, setUserNotice, setSelectedUser } = userSlice.actions;
