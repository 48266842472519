import { useEffect, useState } from "react";
import { Box, Button, CircularProgress, Divider, List, Typography } from "@mui/material";
import AppsIcon from "@mui/icons-material/Apps";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { fetchPlugins } from "../../rtk/features/plugin/pluginSlice";
import MenuItem from "./MenuItem";
import axios from "../../axios";
import { setDrawerOpen } from "../../rtk/features/auth/authSlice";
import { useLocation } from "react-router-dom";

const CustomerSidebar = () => {
  const { plugins, loading: pluginLoading } = useSelector((state) => state.plugin);
  const { user, loading } = useSelector((state) => state.auth);
  const [syncing, setSyncing] = useState(false);
  const location = useLocation();

  const dispatch = useDispatch();

  useEffect(() => {
    if (!loading && user?._id) {
      dispatch(fetchPlugins(user?._id));
    }
  }, [user, loading, dispatch]);

  const handleSync = async () => {
    try {
      setSyncing(true);
      const response = await axios.get(`/api/v1/plugins/sync/${user?._id}`);
      if (response.data?.success) {
        setTimeout(() => {
          dispatch(fetchPlugins(user?._id));
          setSyncing(false);
        }, 2000);
      }
    } catch (error) {
      toast.error(error.message);
      setSyncing(false);
    }
  };

  return (
    <Box onClick={() => dispatch(setDrawerOpen(false))} className="bg-slate-300 overflow-y-scroll h-full overflow-x-hidden" sx={{ width: "280px", minHeight: "calc(100vh - 128px)" }}>
      <>
        {user && (
          <Box className="p-2 flex justify-center flex-col items-center gap-3">
            <Typography className="text-sm text-center w-52">sync data for missing purchased plugin.</Typography>
            <Button disabled={syncing} onClick={handleSync} variant="contained">
              {syncing && <CircularProgress size={20} thickness={5} />}
              Sync Data
            </Button>
            <img className="arrow-up-bounce" width="20px" src="https://cdn.icon-icons.com/icons2/2621/PNG/512/gui_arrow_left_up_curved_icon_157234.png" alt="bounce up arrow" />
          </Box>
        )}
        {pluginLoading && (
          <Box className="text-center">
            <CircularProgress size={50} thickness={5} />
          </Box>
        )}
        <List>
          {plugins?.freemius?.map((plugin) => {
            return <MenuItem className={`${location.pathname.includes(plugin._id) ? "bg-slate-400" : ""}`} key={plugin._id} to={`/plugin/freemius/${plugin._id}`} text={plugin.name} icon={<AppsIcon />} />;
          })}

          <Divider />

          {plugins?.gumroad?.map((plugin) => {
            return <MenuItem isActive={location.pathname.includes(plugin._id)} key={plugin._id} to={`/plugin/gumroad/${plugin._id}`} text={plugin.name} icon={<AppsIcon />} />;
          })}
        </List>
      </>
    </Box>
  );
};

export default CustomerSidebar;
