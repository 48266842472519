import { Box, Button, Card, CardActions, CircularProgress, Modal, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Loader from "../../components/Loader/Loader";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setUserError, setUserNotice, updatePassword, verifyPasswordResetToken } from "../../rtk/features/auth/authSlice";
import { noticePopupStyle } from "../../consttant/style";

const PasswordReset = () => {
  const { token } = useParams();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const { currentUserId, notice, error, subLoading, verificationProcessing, tokenVerified, passwordUpdated } = useSelector((state) => state.auth);
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(verifyPasswordResetToken(token));
  }, [token, dispatch]);

  useEffect(() => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    dispatch(setUserNotice(null));
    dispatch(setUserError(null));
    if (!confirmPassword || !confirmPassword) {
      dispatch(setUserError("Field empty!"));
    } else if (confirmPassword !== password) {
      dispatch(setUserError("Password does not matched!"));
    } else {
      dispatch(updatePassword({ token, userId: currentUserId, password }));
    }
  };

  useEffect(() => {
    if (passwordUpdated) {
      navigate("/login");
    }
  }, [passwordUpdated, navigate]);

  return (
    <>
      {!tokenVerified && (
        <Box className="flex justify-center items-center w-full h-full pt-20">
          {verificationProcessing && <Loader />}
          {!verificationProcessing && !tokenVerified && <Typography className="text-red-500">Password Reset link Invalid or Expired</Typography>}
        </Box>
      )}

      {tokenVerified && (
        <form onSubmit={handleSubmit} noValidate>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              minHeight: "100vh",
              alignItems: "center",
              justifyContent: "flex-start",
              // background: "url(https://source.unsplash.com/featured/1600x900)",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          >
            <Card sx={{ minWidth: 320, maxWidth: "320px", marginTop: "6em" }}>
              {!notice && (
                <>
                  <Box
                    sx={{
                      marginTop: "1em",
                      display: "flex",
                      justifyContent: "center",
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    Password Reset
                  </Box>
                  <Box
                    sx={{
                      marginTop: "1em",
                      display: "flex",
                      justifyContent: "center",
                      color: (theme) => theme.palette.grey[500],
                    }}
                  ></Box>
                  <Box sx={{ padding: "0 1em 1em 1em" }}>
                    <Box sx={{ marginTop: "1em" }}>
                      <TextField onChange={(e) => setPassword(e.target.value)} margin="normal" required fullWidth label="Password" type="password" autoFocus />
                    </Box>

                    <Box sx={{ marginTop: "1em" }}>
                      <TextField className={`text-red-600`} onChange={(e) => setConfirmPassword(e.target.value)} margin="normal" required fullWidth name="password" label="Confirm Password" type="password" id="password" autoComplete="current-password" />
                    </Box>
                  </Box>
                  <CardActions sx={{ padding: "0 1em 1em 1em" }}>
                    <Button variant="contained" type="submit" color="primary" disabled={subLoading} fullWidth>
                      {subLoading && <CircularProgress size={20} thickness={5} />}
                      Update Password
                    </Button>
                  </CardActions>

                  <Box className="text-center p-3">
                    <Typography className="text-red-600" dangerouslySetInnerHTML={{ __html: error }}></Typography>
                  </Box>
                </>
              )}

              {/* {notice && (
                <Box className="text-center">
                  <Typography className="text-green-500 !text-xl !mb-5">{notice}</Typography>
                </Box>
              )} */}

              <Modal open={open} onClose={() => setOpen(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={noticePopupStyle}>
                  <Box className="text-center">
                    <svg className="mx-auto" fill="#37D16B" xmlns="http://www.w3.org/2000/svg" height="60px" viewBox="0 0 512 512">
                      <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z" />
                    </svg>
                  </Box>
                  <Box className="text-center">
                    <Typography className="text-green-500 !text-xl !mb-5">{notice}</Typography>
                  </Box>
                </Box>
              </Modal>
              {passwordUpdated && (
                <Box className="text-center flex justify-center">
                  <Typography className="text-sky-600 !ml-1 !mb-3">
                    <Link to="/Login"> Login Now </Link>
                  </Typography>
                </Box>
              )}
            </Card>
          </Box>
        </form>
      )}
    </>
  );
};

export default PasswordReset;
