import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useSelector } from "react-redux";
import formatDate from "../../utils/formatDate";
import { Box, Button, TextField } from "@mui/material";
import BasicModal from "../../components/Modal/Modal";
import UserDetails from "./UserDetails";

const columns = [{ id: "sl", label: "SL.", minWidth: 50 }, { id: "name", label: "Name", minWidth: 170 }, { label: "Purchased At" }, { label: "Action" }];

export default function UserPluginsTable() {
  const { selectedUser } = useSelector((state) => state.auth);
  const { allPlugins } = useSelector((state) => state.plugin);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searchQuery, setSearchQuery] = React.useState();
  const [filteredUsers, setFilteredUsers] = React.useState([]);
  const [open, setOpen] = React.useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const { freemius, gumroad } = allPlugins[selectedUser?._id] || { freemius: [], gumroad: [] };
  const plugins = [...freemius, ...gumroad];

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  React.useEffect(() => {
    if (searchQuery) {
      const { freemius, gumroad } = allPlugins[selectedUser?._id] || {};
      const plugins = [...freemius, ...gumroad];
      setFilteredUsers(
        plugins?.filter((plugin) => {
          return plugin.name.toLowerCase().includes(searchQuery.toLowerCase());
        })
      );
    } else {
      const { freemius, gumroad } = allPlugins[selectedUser?._id] || {};
      if (gumroad) {
        setFilteredUsers([...freemius, ...gumroad]);
      }
    }
  }, [searchQuery, selectedUser?._id, allPlugins]);

  return (
    <Box className="!mt-10">
      <Box className="text-right !mb-3" sx={{ maxWidth: "900px", margin: "0 auto", width: "100%" }}>
        <TextField className="" name="searchQuery" placeholder="Search" label="Search" onChange={(e) => setSearchQuery(e.target.value)} type="text" />
      </Box>
      <Paper sx={{ maxWidth: "1100px", margin: "0 auto", width: "100%", overflow: "hidden" }}>
        <TableContainer className="text-left" sx={{ maxHeight: 650 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column, index) => (
                  <TableCell key={index} align={column.align} style={{ minWidth: column.minWidth }}>
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredUsers?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((plugin, index) => (
                <TableRow hover role="checkbox" tabIndex={-1} key={plugin._id}>
                  <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                  <TableCell>{plugin.name}</TableCell>
                  <TableCell>{formatDate(new Date(plugin.createdAt), "Y-m-d H:i:s")}</TableCell>
                  <TableCell>
                    <Button>Details</Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination rowsPerPageOptions={[10, 25, 100]} component="div" count={plugins?.length} rowsPerPage={rowsPerPage} page={page} onPageChange={handleChangePage} onRowsPerPageChange={handleChangeRowsPerPage} />
      </Paper>
      <BasicModal width="900px" className="max-w-full" handleClose={handleClose} handleOpen={handleOpen} open={open}>
        <UserDetails handleClose={handleClose} />
      </BasicModal>
    </Box>
  );
}
