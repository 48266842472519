import React from "react";

const SVGLogo = ({ width = "200px" }) => {
  return (
    <div>
      <style type="text/css">
        {`.st0{opacity:0.6;fill:#452784;}
	.st1{fill:#452784;}`}
      </style>
      <svg width={width} version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 234.5 47" style={{ enableBackground: "new 0 0 234.5 47" }}>
        <path
          className="st0"
          d="M44,29.4C44,36,38.6,41.4,32,41.4h-8.3V30.7c0-3.6,2.9-6.5,6.5-6.5h1.4c3.3,0,6.4-1.3,8.6-3.5
	c0.1,0.1,0.2,0.2,0.3,0.2C42.7,23,44,26.1,44,29.4z"
        />
        <path
          className="st1"
          d="M44,12.1c0,3.3-1.5,6.4-3.8,8.5c-2.3,2.2-5.3,3.5-8.6,3.5h-1.4c-3.6,0-6.5,2.9-6.5,6.5v10.8h-8.4
	c-1,0-1.8-0.8-1.8-1.8l0-13.5c0-0.8-0.5-1.6-1.2-1.9c-0.8-0.3-1.6-0.1-2.1,0.4c0,0,0,0,0,0c-1.1,1.1-2.6,1.9-4.3,1.9
	c-3.4,0-6.2-3-5.8-6.5c0.3-2.7,2.6-4.9,5.3-5.1c1.9-0.2,3.6,0.6,4.8,1.8c0.5,0.4,1.1,0.7,1.8,0.5c0.9-0.2,1.5-1.1,1.5-2l0-13.5
	c0-1,0.8-1.8,1.8-1.8l5.9,0c1,0,2,0.8,2.1,1.8c0,0.6-0.2,1.2-0.6,1.5c-1.2,1.1-1.9,2.6-1.9,4.3c0,3.2,2.6,5.8,5.8,5.8
	c3.2,0,5.8-2.6,5.8-5.8c0-1.7-0.7-3.2-1.9-4.3c0,0,0,0,0,0c-0.4-0.5-0.7-1.1-0.5-1.8c0.2-0.9,1.1-1.5,2-1.5c3.3,0,6.2,1.4,8.4,3.5
	C42.7,5.7,44,8.7,44,12.1z"
        />
        <g>
          <path
            className="st1"
            d="M203.5,12.4c-4.6,0-5.9,3.2-5.9,3.2H197v-1.9c0-0.5-0.3-0.9-0.9-0.9h-4.4c-0.5,0-0.9,0.3-0.9,0.9v22.5
		c0,0.5,0.3,0.9,0.9,0.9h4.4c0.5,0,0.9-0.3,0.9-0.9V21.8c0-2.9,1.9-4.3,4.1-4.3c2.1,0,4,1.3,4,4.3v14.5c0,0.5,0.3,0.9,0.9,0.9h4.4
		c0.5,0,0.8-0.3,0.8-0.9V21C211.2,15.7,208.1,12.4,203.5,12.4z"
          />
          <path
            className="st1"
            d="M181.9,2.6c-2.2,0-3.9,1.6-3.9,3.8c0,2.2,1.7,3.8,3.9,3.8c2.2,0,3.9-1.6,3.9-3.8
		C185.8,4.2,184.1,2.6,181.9,2.6z"
          />
          <path
            className="st1"
            d="M184.1,12.9h-4.4c-0.5,0-0.9,0.3-0.9,0.9v22.5c0,0.5,0.3,0.9,0.9,0.9h4.4c0.5,0,0.9-0.3,0.9-0.9V13.7
		C184.9,13.2,184.6,12.9,184.1,12.9z"
          />
          <path
            className="st1"
            d="M234.4,28.3c-0.5-2.7-2.4-4.2-4.8-5c-1.3-0.4-2.6-0.7-3.9-1c-1.4-0.3-3.9-0.3-4.6-1.9c-0.6-1.4,0.6-2.6,1.8-3
		c2.5-0.7,4.4,0.1,5.7,2.3c0.3,0.6,0.7,0.8,1.3,0.6c1.1-0.4,2.1-0.8,3.2-1.2c0.7-0.3,0.9-0.7,0.5-1.4c-0.4-0.8-0.8-1.6-1.4-2.3
		c-3.3-3.7-10.7-4-14.7-0.8c-3,2.5-3.8,8.9,1.4,11.2c1,0.4,2,0.6,3,0.8c1.6,0.4,3.2,0.6,4.8,1c0.9,0.2,1.9,0.8,2,1.9
		c0.1,1-0.5,1.9-1.7,2.3c-2.2,0.8-5.9,0.6-7.2-2.3c-0.3-0.7-0.8-0.9-1.5-0.6c-1,0.4-2.1,0.8-3.1,1.1c-0.8,0.3-0.7,0.7-0.5,1.4
		c1,3.1,4.1,5,7.2,5.7c0,0,0.1,0,0.1,0c0.1,0,0.2,0,0.2,0c1.6,0.3,3.1,0.3,4.8,0c1-0.2,2-0.5,2.9-1c0.2-0.1,0.4-0.3,0.7-0.4
		C233.5,34.5,234.9,31.4,234.4,28.3z"
          />
        </g>
        <path
          className="st1"
          d="M86.7,5.2c0-0.5,0.4-0.9,0.9-0.9h10.6c3.3,0,5.9,1,7.9,3.1c2,2,3,4.7,3,8c0,3.2-1,5.9-3.1,7.9
	c-2.1,2-4.8,3-8.1,3h-5.4v9.9c0,0.5-0.4,0.9-0.9,0.9h-4.1c-0.5,0-0.9-0.4-0.9-0.9V5.2z M92.6,9.3v12.2h4.1c2.1,0,3.7-0.5,4.9-1.6
	c1.2-1.1,1.7-2.6,1.7-4.5c0-2-0.6-3.5-1.7-4.6c-1.1-1.1-2.8-1.6-4.9-1.6H92.6z"
        />
        <path
          className="st1"
          d="M141.5,12.9c-0.5,0-0.9,0.3-0.9,0.9v14.5c0,2.9-1.9,4.3-4.1,4.3c-2.1,0-4-1.3-4-4.3V13.7c0-0.5-0.3-0.9-0.9-0.9
	h-4.4c-0.5,0-0.8,0.3-0.8,0.9v15.2c0,5.4,3.1,8.6,7.7,8.6c4.6,0,5.9-3.2,5.9-3.2h0.5v1.9c0,0.5,0.3,0.9,0.9,0.9h4.4
	c0.5,0,0.9-0.3,0.9-0.9V13.7c0-0.5-0.3-0.9-0.9-0.9H141.5z"
        />
        <path
          className="st1"
          d="M73.2,12.4c-4.4,0-6,2.8-6,2.8h-0.5V4.8c0-0.5-0.3-0.9-0.9-0.9h-4.4c-0.5,0-0.8,0.3-0.8,0.9v31.4
	c0,0.5,0.3,0.9,0.8,0.9h4.4c0.5,0,0.9-0.3,0.9-0.9v-1.6h0.5c0,0,1.6,2.8,6,2.8c6.2,0,9.9-5.5,9.9-12.5S79.4,12.4,73.2,12.4z
	 M71.6,32.4c-3.5,0-5.2-3.1-5.2-7.4s1.7-7.4,5.2-7.4c3.5,0,5.2,3.1,5.2,7.4S75.1,32.4,71.6,32.4z"
        />
        <path
          className="st1"
          d="M122.9,37.1c0.5,0,0.9-0.3,0.9-0.9v-3.6c0-0.5-0.3-0.8-0.9-0.8h-2.6c-1,0-1.6-0.6-1.6-1.6V4.8
	c0-0.5-0.3-0.9-0.9-0.9h-4.4c-0.5,0-0.8,0.3-0.8,0.9v25.9c0,4.1,2.2,6.4,6.3,6.4H122.9z"
        />
        <path
          className="st1"
          d="M172.8,32.1c0-0.6,0-1.1,0-1.7c0-4.7,0-9.4,0-14c0-0.9,0-1.8,0-2.7c0-0.5-0.3-0.9-0.8-0.9h-4.4
	c-0.5,0-0.9,0.3-0.9,0.9v1.6h-0.5c0,0-1.6-2.8-6-2.8c-6.2,0-9.9,5.5-9.9,12.5c0,7,3.7,11.4,9.9,11.4c4.3,0,6-2.8,6-2.8h0.5
	c0,0.1,0,0.1,0,0.2c0,0,0,1.9,0,1.9c-0.1,2.3-0.3,4.9-2.7,5.9c-0.4,0.2-0.8,0.3-1.3,0.4c-4.1,0.5-5.8-1.9-6.4-3.1
	c-0.2-0.4-0.7-0.6-1.1-0.4l-3.4,1.5c-0.4,0.2-0.6,0.7-0.4,1.1c0.7,1.5,2.8,4.8,7.7,5.7c3.4,0.6,7.3-0.1,9.9-2c2.9-2.2,3.6-5.6,3.8-9
	C172.9,34.5,172.9,33.3,172.8,32.1z M161.9,31.2c-2.5,0-4.3-1.2-4.9-3.7c-0.9-3.7,0-10.1,4.9-10.1c3.5,0,5.2,3.1,5.2,7.4
	C167.1,29.3,165.4,31.2,161.9,31.2z"
        />
      </svg>
    </div>
  );
};

export default SVGLogo;
