function formatDate(date, format) {
  const options = {};

  // Format year
  if (format.includes("Y")) {
    options.year = format.length >= 4 ? "numeric" : "2-digit";
  }

  // Format month
  if (format.includes("m")) {
    options.month = format.length >= 2 ? "2-digit" : "numeric";
  }

  // Format day
  if (format.includes("d")) {
    options.day = format.length >= 2 ? "2-digit" : "numeric";
  }

  // Format hours
  if (format.includes("H")) {
    options.hour = "2-digit";
  }

  // Format minutes
  if (format.includes("i")) {
    options.minute = "2-digit";
  }

  // Format seconds
  if (format.includes("s")) {
    options.second = "2-digit";
  }

  return new Intl.DateTimeFormat("default", options).format(date);
}

export default formatDate;
