import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import validateError from "../../../utils/validateError";
import axios from "../../../axios";

const initialState = {
  options: {},
  loading: false,
  subLoading: false,
  error: null,
  notice: null,
};

export const fetchOptions = createAsyncThunk("option/fetchOptions", async () => {
  try {
    const { data } = await axios.get("/api/v1/options");
    const modifiedData = {};
    data.data.map((item) => {
      modifiedData[item.key] = item.value;
    });
    return modifiedData;
  } catch (error) {
    throw new Error(validateError(error));
  }
});

export const fetchOption = createAsyncThunk("option/fetchOption", async (key) => {
  try {
    const response = await axios.get(`/api/v1/options/key/${key}`);

    return response.data.data;
  } catch (error) {
    throw new Error(validateError(error));
  }
});

export const updateOption = createAsyncThunk("option/updateOption", async (info) => {
  try {
    const response = await axios.patch(`/api/v1/options/key/${info.key}`, info.value);
    return { key: info.key, value: response.data?.value };
  } catch (error) {
    throw new Error(validateError(error));
  }
});

export const addOption = createAsyncThunk("option/addOption", async (info) => {
  try {
    const response = await axios.patch(`/api/v1/options/${info._id}`, info);

    return response.data.data;
  } catch (error) {
    throw new Error(validateError(error));
  }
});

const optionSlice = createSlice({
  name: "option",
  initialState,
  reducers: {
    setOptionNotice: (state, action) => {
      state.notice = action.payload;
    },
    setOptionError: (state, action) => {
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchOptions.pending, (state) => {
      state.subLoading = true;
      state.error = null;
    });
    builder.addCase(fetchOptions.fulfilled, (state, action) => {
      state.subLoading = false;
      state.options = action.payload;
    });
    builder.addCase(fetchOptions.rejected, (state, action) => {
      state.subLoading = false;
      state.error = action.error.message;
    });

    builder.addCase(updateOption.pending, (state) => {
      state.subLoading = true;
      state.error = null;
    });
    builder.addCase(updateOption.fulfilled, (state, action) => {
      state.subLoading = false;
      state.options[action.payload.key] = action.payload.value;
    });
    builder.addCase(updateOption.rejected, (state, action) => {
      state.subLoading = false;
      state.error = action.error.message;
    });

    builder.addCase(fetchOption.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchOption.fulfilled, (state, action) => {
      state.loading = false;
      state.options[action.payload.key] = action.payload.value;
    });
    builder.addCase(fetchOption.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export default optionSlice.reducer;
