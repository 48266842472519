import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setUserRole } from "../../rtk/features/auth/authSlice";

const PrivateRoute = ({ requiredRole = "customer", children }) => {
  const { activeRole, user, loading } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(setUserRole(localStorage.getItem("userRole") || "customer"));
  // }, [user, dispatch]);

  useEffect(() => {
    if ((!user && !loading) || (user && !user.emailVerified)) {
      navigate("/login");
    } else if (!loading && !user?.roles.includes(requiredRole)) {
      navigate("/");
    } else {
      dispatch(setUserRole(!user?.roles?.includes("admin") ? "customer" : localStorage.getItem("activeRole") || "customer"));
    }
  }, [user, navigate, requiredRole, activeRole, loading, dispatch]);

  return children;
};

export default PrivateRoute;
