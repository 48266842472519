import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useDispatch, useSelector } from "react-redux";
import formatDate from "../../utils/formatDate";
import { Button, CircularProgress } from "@mui/material";
import { deleteLog } from "../../rtk/features/log/logSlice";

const columns = [{ id: "type", label: "Type", minWidth: 170 }, { label: "Log", minWidth: 100 }, { label: "Created At" }, { label: "Action" }];

export default function LogsTable() {
  const { logs, subLoading } = useSelector((state) => state.log);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const dispatch = useDispatch();
  const [currentLog, setCurrentLog] = React.useState(null);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleLogDelete = (log) => {
    setCurrentLog(log._id);
    dispatch(deleteLog(log._id));
  };

  return (
    <Paper className="!mt-10" sx={{ maxWidth: "900px", margin: "0 auto", width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 650 }}>
        <Table stickyHeader aria-label="sticky table" className="bg-gray-800">
          <TableHead>
            <TableRow className="!bg-red-500">
              {columns.map((column) => (
                <TableCell className="!bg-black !text-gray-50" key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {logs.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((log) => {
              return (
                <TableRow className={`${log.type === "error" ? "!bg-red-500" : ""}`} hover role="checkbox" tabIndex={-1} key={log._id}>
                  <TableCell className="!text-gray-50">{log.type}</TableCell>
                  <TableCell className="!text-gray-50">{log.log}</TableCell>
                  <TableCell className="!text-gray-50">{log.createdAt && formatDate(new Date(log.createdAt), "Y-m-d H:i:s")}</TableCell>
                  <TableCell>
                    <Button disabled={subLoading} className="!text-gray-50" variant="contained" onClick={() => handleLogDelete(log)}>
                      {subLoading && currentLog === log._id && <CircularProgress size={20} thickness={5} />}
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination rowsPerPageOptions={[10, 25, 100]} component="div" count={logs.length} rowsPerPage={rowsPerPage} page={page} onPageChange={handleChangePage} onRowsPerPageChange={handleChangeRowsPerPage} />
    </Paper>
  );
}
