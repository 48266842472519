import { Box, Typography } from "@mui/material";
import React, { useEffect } from "react";
import Loader from "../../components/Loader/Loader";
import { useNavigate, useParams } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { verifyEmailToken } from "../../rtk/features/auth/authSlice";

const VerifyEmail = () => {
  const { token } = useParams();
  const dispatch = useDispatch();
  const { user, verificationProcessing, tokenVerified } = useSelector((state) => state.auth);

  const navigate = useNavigate();

  React.useEffect(() => {
    if (user?.emailVerified) {
      navigate("/");
    }
  }, [user, navigate]);

  useEffect(() => {
    dispatch(verifyEmailToken(token));
  }, [token, dispatch]);

  useEffect(() => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
  }, []);

  return (
    <Box className="w-full flex justify-center items-center h-full mt-20">
      {verificationProcessing && <Loader />}
      {!verificationProcessing && !tokenVerified && <Typography className="text-red-500">Verification link Invalid or Expired</Typography>}
    </Box>
  );
};

export default VerifyEmail;
