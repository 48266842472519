import { Button, Card, CardActions, CircularProgress, TextField, Typography } from "@mui/material";

import Box from "@mui/material/Box";
import { useDispatch, useSelector } from "react-redux";
import { setUserError, updateProfile } from "../../rtk/features/auth/authSlice";

import { useEffect, useState } from "react";

const Profile = () => {
  const { user, error, subLoading, notice } = useSelector((state) => state.auth);
  const [data, setData] = useState({ name: user?.name });
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setUserError(null));
  }, [dispatch]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!data.name.firstName) {
      dispatch(setUserError("Field empty!"));
    } else {
      dispatch(updateProfile({ userId: user._id, info: data }));
    }
  };

  const handleFormInput = (e) => {
    if (e.target.name.includes(".")) {
      const steps = e.target.name.split(".");
      const tempVar = { ...data[steps[0]], [steps[1]]: e.target.value };
      setData({ ...data, [steps[0]]: tempVar });
    } else {
      setData({ ...data, [e.target.name]: e.target.value });
    }
  };
  return (
    <Box>
      <Box className="bg-slate-200">
        <Typography className="text-center !text-3xl" variant="h6" noWrap component="div">
          Profile
        </Typography>
      </Box>
      <Box className="mt-10">
        {" "}
        <Box onSubmit={handleSubmit} component={"form"} noValidate className="w-full">
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "flex-start",
              // background: "url(https://source.unsplash.com/featured/1600x900)",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              width: "100%",
            }}
          >
            <Card sx={{ minWidth: 300, maxWidth: "472px" }}>
              <Box
                sx={{
                  margin: "1em",
                  display: "flex",
                  justifyContent: "center",
                }}
                className="pt-3"
              ></Box>
              <Box
                sx={{
                  marginTop: "1em",
                  display: "flex",
                  justifyContent: "center",
                  color: (theme) => theme.palette.grey[500],
                }}
              ></Box>
              <Box sx={{ padding: "0 1em 1em 1em" }}>
                <Box className="flex gap-5" sx={{ marginTop: "1em" }}>
                  <TextField onChange={handleFormInput} value={data?.name?.firstName} margin="normal" required fullWidth id="email" label="First Name" name="name.firstName" autoComplete="firstName" autoFocus />
                  <TextField onChange={handleFormInput} value={data?.name?.lastName} margin="normal" required fullWidth id="email" label="Last Name" name="name.lastName" autoComplete="lastName" autoFocus />
                </Box>
                <TextField aria-readonly disabled value={user?.email} margin="normal" fullWidth id="email" label="Email" autoFocus />
              </Box>
              <CardActions sx={{ padding: "0 1em 1em 1em" }}>
                <Button variant="contained" type="submit" color="primary" fullWidth disabled={subLoading}>
                  {subLoading && <CircularProgress size={20} thickness={4} />}
                  Update
                </Button>
              </CardActions>
              <Box className="text-center p-3">
                <Typography className="text-red-600">{error}</Typography>
              </Box>
              {notice && (
                <Box className="text-center p-3">
                  <Typography className="text-green-500">{notice}</Typography>
                </Box>
              )}
            </Card>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Profile;
