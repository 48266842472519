import * as React from "react";
import { useState } from "react";

import { Button, Card, CardActions, CircularProgress, FormControl, FormControlLabel, FormLabel, RadioGroup, Radio, Switch, TextField, Typography } from "@mui/material";

import Box from "@mui/material/Box";
import { useDispatch, useSelector } from "react-redux";

import Sidebar from "../../components/Sidebar/Sidebar";
import { fetchOption, updateOption } from "../../rtk/features/option/optionSlice";

const SMTPSetup = () => {
  const { options, subLoading, error } = useSelector((state) => state.option);
  const [data, setData] = useState(options.smtpConfig || { host: "", port: 587, authenticate: false, encryption: "none" });
  const dispatch = useDispatch();

  const handleSubmit = async (event) => {
    event.preventDefault();
    dispatch(updateOption({ key: "smtpConfig", value: data }));
    console.log(data);
  };

  React.useEffect(() => {
    dispatch(fetchOption("smtpConfig"));
  }, [dispatch]);

  React.useEffect(() => {
    setData(options?.smtpConfig);
  }, [options?.smtpConfig]);

  const handleFormInput = (e) => {
    if (e.target.name.includes(".")) {
      const steps = e.target.name.split(".");
      const tempVar = { ...data[steps[0]], [steps[1]]: e.target.value };
      setData({ ...data, [steps[0]]: tempVar });
    } else {
      const value = e.target.value === "on" ? e.target.checked : e.target.value;
      setData({ ...data, [e.target.name]: value });
    }
  };

  console.log("smtpConfig", options);

  return (
    <Box className="flex flex-row">
      <Box>
        <Sidebar />
      </Box>
      <form onSubmit={handleSubmit} noValidate className="w-full">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            minHeight: "calc(100vh - 64px)",
            alignItems: "center",
            justifyContent: "flex-start",
            background: "url(https://source.unsplash.com/featured/1600x900)",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            width: "100%",
          }}
        >
          <Card sx={{ minWidth: 300, marginTop: "6em", maxWidth: "472px" }}>
            <Box
              sx={{
                margin: "1em",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {/* <Avatar sx={{ bgcolor: "secondary.main" }}><LockIcon /></Avatar> */}
              <Typography className="text-5xl pt-5">SMTP Setup</Typography>
            </Box>
            <Box
              sx={{
                marginTop: "1em",
                display: "flex",
                justifyContent: "center",
                color: (theme) => theme.palette.grey[500],
              }}
            ></Box>
            <Box sx={{ padding: "0 1em 1em 1em" }}>
              <Box className="flex gap-5" sx={{ marginTop: "1em" }}>
                <TextField onChange={handleFormInput} value={data?.host} margin="normal" required fullWidth id="host" label="SMTP Host" name="host" autoComplete="host" autoFocus />
                <TextField onChange={handleFormInput} value={data?.port} margin="normal" required fullWidth id="port" label="SMTP Port" name="port" autoComplete="port" autoFocus />
              </Box>
              <Box className="flex gap-5" sx={{ marginTop: "1em" }}>
                <FormControl className="flex justify-between !flex-row w-full items-center">
                  <FormLabel id="demo-radio-buttons-group-label">ENCRYPTION</FormLabel>
                  <RadioGroup className="flex !flex-row" aria-labelledby="demo-radio-buttons-group-label" defaultValue={"none"} name="radio-buttons-group">
                    <FormControlLabel value="none" control={<Radio checked={data?.encryption === "none"} onChange={handleFormInput} value="none" name="encryption" />} label="None" />
                    <FormControlLabel value="ssl" control={<Radio checked={data?.encryption === "ssl"} onChange={handleFormInput} value="ssl" name="encryption" />} label="SSL" />
                    <FormControlLabel value="tls" control={<Radio checked={data?.encryption === "tls"} onChange={handleFormInput} value="tls" name="encryption" />} label="TLS" />
                  </RadioGroup>
                </FormControl>
              </Box>
              <Box>
                <FormControlLabel className="flex mt-2 !ml-0" control={<Switch onChange={handleFormInput} checked={data?.authenticate} name="authenticate" color="primary" />} label="Authenticate" labelPlacement="start" />
              </Box>
              {data?.authenticate && (
                <>
                  <Box className="flex gap-5" sx={{ marginTop: "1em" }}>
                    <TextField onChange={handleFormInput} value={data?.username} margin="normal" required fullWidth id="username" label="SMTP Username" name="username" autoComplete="username" autoFocus />
                    <TextField onChange={handleFormInput} value={data?.password} margin="normal" required fullWidth id="password" label="SMTP Password" name="password" autoComplete="password" autoFocus />
                  </Box>
                </>
              )}
            </Box>
            <CardActions sx={{ padding: "0 1em 1em 1em" }}>
              <Button variant="contained" type="submit" color="primary" fullWidth disabled={subLoading}>
                {subLoading && <CircularProgress size={20} thickness={4} />}
                Save Changes
              </Button>
            </CardActions>
            <Box className="text-center p-3">
              <Typography className="text-red-600">{error}</Typography>
            </Box>
          </Card>
        </Box>
      </form>
    </Box>
  );
};

export default SMTPSetup;
