import * as React from "react";
import { Link, useLocation } from "react-router-dom";

import { Divider, List, ListItem, ListItemButton, ListItemText } from "@mui/material";

import Box from "@mui/material/Box";

import Sidebar from "../../components/Sidebar/Sidebar";
import Profile from "./Profile";
import ResetPassword from "./ResetPassword";

const MyAccount = () => {
  const location = useLocation();
  const slug = location.pathname.substring(1);

  return (
    <Box className="flex flex-row">
      <Box>
        <Sidebar />
      </Box>
      <Box className="flex m-10 overflow-hidden justify-center w-full">
        <Box className="flex w-full h-full">
          <Box className="bg-slate-300" sx={{ width: "240px" }}>
            <List>
              {["Profile", "Reset Password"].map((text, index) => {
                const slug = text.trim().replace(" ", "-").toLowerCase();
                return (
                  <Link key={text} to={`/my-account/${slug}`}>
                    <ListItem disablePadding className={`${location.pathname.includes(slug) ? "bg-slate-400" : ""}`}>
                      <ListItemButton>
                        {/* <ListItemIcon>{Icon}</ListItemIcon> */}
                        <ListItemText primary={text} />
                      </ListItemButton>
                    </ListItem>
                    <Divider />
                  </Link>
                );
              })}
            </List>
          </Box>
          <Box className="bg-slate-200 p-10" sx={{ width: { sm: `calc(100% - 240px)` } }}>
            {slug.includes("profile") && <Profile />}
            {slug.includes("reset-password") && <ResetPassword />}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default MyAccount;
